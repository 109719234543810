<template>
  <div>
    <v-card
      title="Start"
      color="success"
      density="compact"
      data-cy="startPoint"
    >
      <v-card-text> {{ typeText }} </v-card-text>
    </v-card>
    <Handle
      id="b"
      type="source"
      :position="Position.Bottom"
      :style="{ bottom: '-10px' }"
    />
  </div>
</template>
<script setup>
import { Handle, Position } from '@vue-flow/core'
</script>
<script>
export default {
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  computed: {
    typeText() {
      switch (this.type) {
        case 'external':
          return 'für alle Außenrotanden'
        default:
          return 'für alle Rotanden'
      }
    },
  },
}
</script>
